import React from "react";
import Main from "../Components/Main/Main";
import hero from "../Images/hero.png";

function Home() {
  return (
    <>
      <div className="bg-customGradiunt sm:py-40 py-[100px]">
        <div className="xl:grid xl:grid-cols-2 flex flex-col-reverse max-w-[1400px] mx-auto gap-[60px] xl:gap-6 items-center px-4">
          <div>
            <h2 className="font-medium text-white md:text-6xl md:leading-[70px] text-4xl xl:mb-10 mb-[30px]">
              Welcome to{" "}
              <span className="text-[#FFC104] whitespace-nowrap">
                Edelta POC
              </span>
            </h2>
            <p className="text-[24px] leading-[28px] text-indigo-200 font-light">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
          <div>
            <img src={hero} alt="headerimg " className="mx-auto" />
          </div>
        </div>
      </div>
      <Main />
    </>
  );
}

export default Home;
