import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="sm:pt-[60px] pt-10">
      <div className="bg-[#EEF0F4] py-5 grid gap-5 ">
        <div className="flex justify-center space-x-4">
          <Link to="/">
            {" "}
            <p className="font-medium text-sm text-ThemeColor  hover:underline ">
              Terms of Service
            </p>
          </Link>
          <p className="h-5 bg-[#122B6C] w-[1px]"></p>
          <Link to="/">
            {" "}
            <p className="font-medium text-sm text-ThemeColor  hover:underline">
              Privacy Policy
            </p>
          </Link>
        </div>
        <p className="text-center text-sm text-slate-500">
          Copyright © 2024 POCWeb. All rights reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
