import React from "react";
import Title from "../Title/Title";
import { IoSearch } from "react-icons/io5";
import AIStudio from "../../Images/AIStudio.png";
import avatar from "../../Images/avatar.png";
import pocTitle from "../../Images/pocTitle.png";
import form from "../../Images/form1.png";
import { Link } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { TbExternalLink } from "react-icons/tb";

function Main() {
  const Details = [
    {
      image: AIStudio,
      title: "Revolutionize Customer Engagement with AI Studio",
      linktext: "revolutionize-customer-engagement-with-ai-studio",
      discription:
        "Create, customize, and deploy intelligent chatbots without coding. Easily design conversation flows, integrate with your ...",
      more: "See more Details",
      goto: "Go To",
    },
    {
      image: form,
      title: "Turn Paper Forms into Smart Forms",
      linktext: "turn-paper-forms-into-smart-forms",
      discription:
        "Paper forms got you bogged down? Form IQ converts them to online forms in seconds! Share, collect, analyse - ditch the paper, go...",
      more: "See more Details",
      goto: "Go To",
    },
    {
      image: avatar,
      title: "AI Interactive Digital Avatars",
      linktext: "ai-interactive-digital-avatars",
      discription:
        "Our extensive ai avatars from various ethnicities, genders, and ages. You can choose from cartoonish characters for a whimsical...",
      more: "See more Details",
      goto: "Go To",
    },
    {
      image: pocTitle,
      title: "POC Title",
      linktext: "",
      discription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text...",
      more: "See more Details",
      goto: "Go To",
    },
  ];
  return (
    <div className="sm:pb-[60px] pb-10 sm:pt-[120px] pt-[80px] max-w-[1432px] mx-auto grid gap-[40px] px-4">
      <Title
        Title={"Title"}
        Description={
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
        }
      />

      <div className="flex justify-center">
        <div className="w-full max-w-[688px] border border-gray-200 rounded-[40px] py-2 pl-4 pr-2 bg-slate-200 flex items-center gap-2">
          <input
            type="text"
            placeholder="Search POC"
            id="search"
            className="flex-auto placeholder-textColor bg-transparent focus:outline-none"
          />
          <div className="p-2 rounded-full bg-[#122B6C] text-[20px] cursor-pointer size-10 flex items-center justify-center">
            <IoSearch className="text-white" />
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
        {Details.map((data, index) => (
          <div
            key={index}
            className="shadow-custom-shadow flex flex-col border  border-gray-200 rounded-xl overflow-hidden bg-white hover:shadow-xl transition-all"
          >
            <img src={data?.image} alt={data?.title} className="" />
            <div className="py-6 px-4 h-full flex flex-col gap-6">
              <div className="space-y-3 h-full">
                <h3 className="font-medium text-[18px] leading-[21px]">
                  {data?.title}
                </h3>
                <p className="text-[14px] leading-[16px] text-gray-500 line-clamp-3">
                  {data?.discription}
                </p>
              </div>
              <div className="grid items-end gap-6 ">
                <div>
                  <Link
                    to={`/${data?.linktext}`}
                    className="flex space-x-[6px] items-center w-fit hover:underline underline-offset-4 text-blue-600"
                  >
                    <p className="font-medium">{data?.more}</p>
                    <div className="text-[20px] animate-[arrow_1s_infinite]">
                      <FaChevronRight size={16} />
                    </div>
                  </Link>
                </div>
                <Link
                  to="/"
                  className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all text-base font-medium py-3 px-4 items-center gap-[10px]"
                >
                  {data?.goto}
                  <TbExternalLink className="size-5" />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Main;
