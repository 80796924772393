import React, { useState } from "react";
import Title from "../../Components/Title/Title";
import { LuPlus } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";

function Faq() {
  const [isOpen, setIsOpen] = useState(null);
  const [faqheight, setFaqheight] = useState();
  const handleOpen = (index) => {
    if (isOpen === index) {
      setIsOpen();
    } else {
      setIsOpen(index);
      const totalheight = document.getElementById(`faq${index}`);
      setFaqheight(totalheight?.clientHeight);
    }
  };

  const faq = [
    {
      question: "What is AI Studio?",
      answer:
        "AI Studio is a no-code application that allows one to build and test, or build and prototype AI solutions.",
    },
    {
      question: "What can I build with AI Studio?",
      answer:
        "AI Studio provides tools and environments to facilitate these tasks, enabling you to leverage AI technology for a wide range of practical applications.",
    },
    {
      question: "What AI models can I use with AI Studio?",
      answer:
        "AI Models can be adapted for various applications, depending on your project requirements",
    },
    {
      question: "Can I integrate AI Studio with my existing systems?",
      answer:
        "Yes, AI Studio can be integrated with your existing systems via APIs, SDKs, and connectors",
    },
  ];

  return (
    <div className="sm:py-[60px] py-10">
      <Title
        Title={"Frequently Asked Questions"}
        Description={
          "Connect with your customers wherever they are, on any platform, all through one intuitive interface."
        }
        className=""
      />
      <div className="grid grid-cols-12 mt-10">
        <div className="lg:col-start-2 lg:col-span-10 col-span-12 grid gap-7 ">
          {faq.map((item, index) => (
            <div key={index} className="bg-gray-100 p-6 rounded-xl">
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => handleOpen(index)}
              >
                <p className="text-xl font-medium">{item.question}</p>
                <div
                  className={`transition-all`}
                  onClick={() => handleOpen(index)}
                >
                  {isOpen === index ? (
                    <IoMdClose size={24} />
                  ) : (
                    <LuPlus size={24} />
                  )}
                </div>
              </div>
              <div
                className={`overflow-hidden transition-all`}
                style={{ height: isOpen === index ? faqheight : 0 }}
              >
                <p
                  className="text-gray-600 leading-5 pt-3 pr-7"
                  id={`faq${index}`}
                >
                  {item.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Faq;
