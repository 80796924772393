import React from "react";

function Contact() {
  return (
    <form className="p-6 bg-gray-100 rounded-2xl grid gap-5">
      <h3 className="pb-5 text-center font-medium text-[32px]">Contact</h3>
      <div className="grid gap-[6px]">
        <p className="font-medium text-base leading-[18px] ">Name:</p>
        <input type="text" className="p-3 border  border-gray-200 rounded-lg" />
      </div>
      <div className="grid gap-[6px]">
        <p className="font-medium text-base leading-[18px] ">Email:</p>
        <input type="email" className="p-3 border border-gray-200 rounded-lg" />
      </div>
      <div className="grid gap-[6px]">
        <p className="font-medium text-base leading-[18px] ">Message:</p>
        <textarea
          type="text"
          className="p-3 border border-gray-200 rounded-lg resize-none h-[100px]"
        />
      </div>
      <div className="grid justify-center py-5">
        <button
          type="submit"
          className="bg-[#122B6C] hover:bg-[#ffaf04] text-white py-3 w-[240px] rounded-[28px] text-[18px] transition-all hover:text-black"
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default Contact;
