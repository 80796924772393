import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TbCheck, TbCopy } from "react-icons/tb";

function CopyTextbtn({ text }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const showtooltip = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };
  return (
    <CopyToClipboard text={text} onCopy={showtooltip}>
      <div className="p-2 rounded-md bg-gray-50 hover:bg-gray-200 text-gray-500">
        {showTooltip ? <TbCheck size={20} /> : <TbCopy size={20} />}
      </div>
    </CopyToClipboard>
  );
}

export default CopyTextbtn;
