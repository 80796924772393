import React from "react";

function Title({ Title, Description }) {
  return (
    <div className="text-center grid gap-2 max-w-[800px] mx-auto">
      <h2 className="font-medium text-ThemeColor sm:text-5xl text-[28px]">
        {Title}
      </h2>
      <p className="text-base text-[#6B7280]">{Description}</p>
    </div>
  );
}

export default Title;
