import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import AIStudioPocDetail from "../../Images/AIStudiopocDetail.png";
import Contact from "./Contact";
import Faq from "./Faq";
import { TbExternalLink } from "react-icons/tb";
import CopyTextbtn from "../../Components/CopyTextbtn";

function PocDetail() {
  const Information = {
    credentials: {
      email: "example@gmail.com",
      password: "example@123",
    },
    detail: {
      description1:
        "Reate, customize, and deploy intelligent chatbots without coding. Easily design conversation flows, integrate with your systems",
      description2:
        "Create, customize, and deploy intelligent chatbots without coding. Easily design conversation flows, integrate with your systems, and provide exceptional customer experiences.",
      description3:
        "Live chat enables users to view ongoing conversations, whether it's an individual chat or a broadcast. This feature allows for seamless communication and collaboration among participants. ",
    },
    step: {
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
      step1:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",

      step2:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",

      step3:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
    },
  };

  return (
    <div className="max-w-[1432px] mx-auto px-4">
      <Link to="/">
        <button
          type="submit"
          className="flex bg-[#EEF0F4] px-4 py-3 rounded-[26px] mt-[60px] text-base hover:bg-[#122B6C] hover:text-white transition-all"
        >
          <FaChevronLeft className="mt-1 mr-2" />
          Back
        </button>
      </Link>
      <h2 className="mt-[38px] text-[32px] font-medium">
        Revolutionize Customer Engagement with AI Studio
      </h2>
      <div className="lg:mt-10 mt-5 grid lg:grid-cols-2 grid-cols-1 mx-auto gap-10 lg:gap-6 sm:pb-[60px] pb-10">
        <div className="grid gap-6">
          <div>
            <img src={AIStudioPocDetail} alt="AIStudio" />
          </div>
          <Link
            to="/"
            className="flex space-x-[6px] justify-center bg-[#FFC104] hover:bg-[#ffaf04] rounded-[28px] transition-all font-bold py-3 px-4 items-cnter gap-[10px] w-[170px]"
          >
            Go To
            <TbExternalLink className="size-5" />
          </Link>
          <div className="grid gap-[30px]">
            <div>
              <h3 className="font-bold text-xl mb-2">Credentials:</h3>
              <div className="flex space-x-2 mb-2 items-center">
                <p className="w-[75px] text-base text-gray-500">Email:</p>
                <p className="text-gray-500 text-base ">
                  {Information?.credentials?.email}
                </p>
                <CopyTextbtn text={Information?.credentials?.email} />
              </div>
              <div className="flex space-x-2 items-center">
                <p className="w-[75px] text-base text-gray-500">Password:</p>
                <p className="text-base text-gray-500">
                  {Information?.credentials?.password}
                </p>
                <CopyTextbtn text={Information?.credentials?.password} />
              </div>
            </div>

            <div className="grid gap-4">
              <h3 className="font-bold text-xl -mb-2">Details:</h3>
              <p className="text-gray-500">
                {Information?.detail?.description1}
              </p>
              <p className="text-gray-500">
                {Information?.detail?.description2}
              </p>
              <p className="text-gray-500">
                {Information?.detail?.description3}
              </p>
            </div>
            <div>
              <h3 className="font-bold text-xl  mb-2">How to use:</h3>
              <p className="mb-4 text-gray-500">
                {Information?.step?.description}
              </p>
              <p className="mb-1 font-semibold">Step1:</p>
              <p className="mb-4 text-gray-500">{Information?.step?.step2}</p>
              <p className="mb-1 font-semibold">Step2:</p>
              <p className="mb-4 text-gray-500">{Information?.step?.step3}</p>
              <p className="mb-1 font-semibold">Step3:</p>
              <p className="text-gray-500">{Information?.step?.step3}</p>
            </div>
          </div>
        </div>
        <div>
          <Contact />
        </div>
      </div>
      <Faq />
    </div>
  );
}

export default PocDetail;
